import "./App.css";
import {
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Grid,
  Card,
  CardHeader,
  CardActionArea,
} from "@mui/material";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {
  AlternateEmail,
  AssignmentInd,
  CloudUpload,
  FolderSpecial,
  Group,
  Park,
  Support,
} from "@mui/icons-material";
function App() {
  const themeDark = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#222222",
      },
      text: {
        primary: "#ffffff",
      },
    },
  });
  return (
    <ThemeProvider theme={themeDark}>
      <Header title="Aplikacje SH" />
      <Container>
        <CssBaseline />
        <Grid container spacing={2} p={2}>
          {/*           <Grid item xs={12}>
            <Alert severity="warning">
              Dostęp do <strong>Bazy ALiZ</strong> tymczasowo niedostępny. Trwają pracę nad przywróceniem sprawności działania.
            </Alert>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <Card elevation={20}>
              <CardActionArea href="http://office.sh.org.pl/">
                <CardHeader
                  avatar={<Group color="error" sx={{ fontSize: 60 }} />}
                  title="Portal Microsoft 365"
                  titleTypographyProps={{ variant: "h5" }}
                  subheader="Microsoft Office Portal | office.sh.org.pl"
                  sx={{ minHeight: 90 }}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={20}>
              <CardActionArea href="http://mail.sh.org.pl/">
                <CardHeader
                  avatar={<AlternateEmail color="info" sx={{ fontSize: 60 }} />}
                  title="Poczta e-mail"
                  titleTypographyProps={{ variant: "h5" }}
                  subheader="Microsoft Outlook | mail.sh.org.pl"
                  sx={{ minHeight: 90 }}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={20}>
              <CardActionArea href="http://dysk.sh.org.pl/">
                <CardHeader
                  avatar={<CloudUpload color="success" sx={{ fontSize: 60 }} />}
                  title="Dysk SH"
                  titleTypographyProps={{ variant: "h5" }}
                  subheader="Microsoft Sharepoint | dysk.sh.org.pl"
                  sx={{ minHeight: 90 }}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={20}>
              <CardActionArea href="http://pliki.sh.org.pl/">
                <CardHeader
                  avatar={
                    <FolderSpecial color="primary" sx={{ fontSize: 60 }} />
                  }
                  title="Moje pliki"
                  titleTypographyProps={{ variant: "h5" }}
                  subheader="Microsoft OneDrive | pliki.sh.org.pl"
                  sx={{ minHeight: 90 }}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={20}>
              <CardActionArea href="https://ewidencja.sh.org.pl/">
                <CardHeader
                  avatar={
                    <AssignmentInd color="warning" sx={{ fontSize: 60 }} />
                  }
                  title="Ewidencja SH"
                  titleTypographyProps={{ variant: "h5" }}
                  subheader="ewidencja.sh.org.pl"
                  sx={{ minHeight: 90 }}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={20}>
              <CardActionArea href="https://aliz.sh.org.pl/">
                <CardHeader
                  avatar={<Park color="secondary" sx={{ fontSize: 60 }} />}
                  title="Baza ALiZ"
                  titleTypographyProps={{ variant: "h5" }}
                  subheader="aliz.sh.org.pl"
                  sx={{ minHeight: 90 }}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card elevation={20}>
              <CardActionArea href="http://helpdesk.sh.org.pl/">
                <CardHeader
                  avatar={<Support color="success" sx={{ fontSize: 60 }} />}
                  title="Helpdesk SH"
                  titleTypographyProps={{ variant: "h5" }}
                  subheader="Jira | helpdesk.sh.org.pl"
                  sx={{ minHeight: 90 }}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Footer />
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default App;
