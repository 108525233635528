import { Grid, Typography } from "@mui/material";
import VersionNumber from "../versionNumber";

const Footer = () => {
  return (
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Typography variant="caption" display="block" gutterBottom>
        <VersionNumber />
      </Typography>
    </Grid>
  );
};

export default Footer;