import React from "react";
import moment from "moment";
import preval from "preval.macro";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

class VersionNumber extends React.Component {
  getDateString = () => {
    const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
    const formattedDate = lastUpdateMoment.format("DD.MM.YYYY HH:mm:ss");

    return formattedDate;
  };

  render() {
    return (
      <>
        {process.env.REACT_APP_NAME}
        {" ("}
        {process.env.REACT_APP_BRANCH}
        {") "}
        {"::"} {process.env.REACT_APP_VERSION} {"::"} {this.getDateString()}
      </>
    );
  }
}

VersionNumber.propTypes = {};

VersionNumber.defaultProps = {};

export default VersionNumber;
